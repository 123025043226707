import React, { useState, useRef, useEffect } from "react";
import { Link, useStaticQuery,graphql } from "gatsby";
import { useLocation } from "@reach/router"
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import parse from 'html-react-parser';
import CountUp from 'react-countup';
import GenerateLink from "../../common/site/generate-link"

import "./CountBlock.scss";
import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";


const COUNT_UP_START = 0;
const COUNT_UP_DURATION = 3;

function addZero(i) {
  if (i < 10) { i = "0" + i }
  return i;
}

const CountBlock = (props) => {
  const location = useLocation();
  const buyerFinderPage = location.pathname.endsWith('/buyer-finder/')
  const data = useStaticQuery(graphql`
    query StatQuery {
      allBranches(filter: {brand_id: {eq: "wk"}}) {
        cash_buyers: sum(field: cash_buyers),
        first_time_buyers: sum(field: first_time_buyers),
        gross_value_sold: sum(field: gross_value_sold),
        mortgages_arranged_value: sum(field: mortgages_arranged_value),
        nothing_to_sell: sum(field: nothing_to_sell),
        over_60s: sum(field: over_60s),
        own_to_sell: sum(field: own_to_sell),
        total_applicants: sum(field: total_applicants),
        under_offer: sum(field: under_offer),
        valuations: sum(field: valuations),
        viewings_arranged: sum(field: viewings_arranged)
      }
    }
  `);
  const statsdata = data.allBranches;
  let list = [];
  if (statsdata.gross_value_sold >= 80000) {
    list.push({ count: '£' + statsdata.gross_value_sold, details: "worth of properties sold in the last 12 months" })
  }
  if (statsdata.viewings_arranged >= 80000) {
    list.push({ count: statsdata.viewings_arranged, details: "viewings arranged in the last 12 months" })
  }
  if (statsdata.total_applicants >= 20000) {
    list.push({ count: statsdata.total_applicants, details: "active buyers registered with us" })
  }
  if (statsdata.valuations >= 15000) {
    list.push({ count: statsdata.valuations, details: "homes valued in the last 12 months" })
  }
  if (statsdata.cash_buyers >= 250) {
    list.push({ count: statsdata.cash_buyers, details: "cash buyers registered with us" })
  }
  if (statsdata.under_offer >= 250) {
    list.push({ count: statsdata.under_offer, details: "buyers registered with us with their property under offer" })
  }
  if (statsdata.own_to_sell >= 250) {
    list.push({ count: statsdata.own_to_sell, details: "active buyers registered with us with a property to sell" })
  }
  if (statsdata.first_time_buyers >= 250) {
    list.push({ count: statsdata.first_time_buyers, details: "first-time buyers registered with us" })
  }

  if (statsdata.over_60s >= 500) {
    list.push({ count: statsdata.over_60s, details: "buyers over 60, eligible for a Home for Life plan" })
  }
  var currentdate = new Date();
  var datetime = currentdate.getDate() + "/"
    + (currentdate.getMonth() + 1) + "/"
    + currentdate.getFullYear() + " @ "
    + addZero(currentdate.getHours()) + ":"
    + addZero(currentdate.getMinutes()) + ":"
    + addZero(currentdate.getSeconds());
  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="count-section left-content components--Home--CountBlock--CountBlock"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <Row>
              <Col lg={3} className="col-one">
                <motion.div variants={contentItem}>
                  {props.Statistics_Left_Title &&
                    <span class="sub-title">{props.Statistics_Left_Title}</span>
                  }
                  {parse(props.Statistics_Left_Content)}
                  {props.Statistics_Left_CTA_Label &&
                    <GenerateLink link={props.Statistics_Left_CTA_Link} class="btn">{props.Statistics_Left_CTA_Label}<i className="icon-arrow"></i></GenerateLink>
                  }
                </motion.div>
              </Col>

              <Col md={9} lg={8} className="ms-auto">
                <Row>
                  <Col md={12} className="ms-auto">
                    <div className="block-heading">
                      {props.Statistics_Title &&
                        <h2>{parse(props.Statistics_Title)}</h2>
                      }
                    </div>
                  </Col>
                  {buyerFinderPage ?
                    <Col lg={12} className="ms-auto">
                      <div className="count-row count-row-first">
                        {list.length > 0 && list.slice(0, 1).map((stats, i) => {
                          // var countnum = stats.count
                          var countnum = (stats.count).toString().replace(/[^\w\s]/gi, '')
                          var prefix = ''
                          if ((stats.count).toString().indexOf('£') !== -1) {
                            prefix = '£'
                          }
                          return (
                            <motion.div variants={contentItem} className="count-block one">
                              <strong><CountUp
                                start={inView ? COUNT_UP_START : countnum}
                                end={countnum}
                                delay={0}
                                duration={COUNT_UP_DURATION}
                                prefix={prefix?'£ ':''}
                                separator=","
                              /></strong>
                              {stats.details && <span>{stats.details}</span>}
                            </motion.div>
                          )
                        })}
                      </div>
                      {list?.length > 3 &&
                        <div className="count-row count-row-conditional">
                          {list && list.slice(1, 5).map((stats, i) => {
                            console.log(stats)
                            var countnum = stats.count
                            return (
                              <motion.div key={stats + 2} custom={stats + 3} variants={contentItem} className="count-block one">
                                <strong>
                                  <CountUp
                                    start={inView ? COUNT_UP_START : countnum}
                                    end={countnum}
                                    delay={0}
                                    duration={COUNT_UP_DURATION}
                                    prefix={''}
                                    suffix={''}
                                    separator=","
                                  />
                                </strong>
                                {stats.details && <span>{stats.details}</span>}
                              </motion.div>
                            )
                          })}

                          {/* {props.Add_Stats && props.Add_Stats.slice(2, 10).map((stats, i) => {
                               var countnum = stats.count.replace(/[^\w\s]/gi, '').replace(/\D/g,'');
                               var prefix = ''
                               var suffix = ''
                               if (stats.Count.indexOf('£') !== -1) {
                                 prefix = '£'
                               }
                               if (stats.Count.charAt(stats.Count.length - 1) == "m") {
                                 suffix = 'm'
                               }
                               return (
                                 <motion.div key={stats + 2} custom={stats + 3} variants={contentItem} className="count-block one">
                                   <strong>
                                     <CountUp
                                       start={inView ? COUNT_UP_START : countnum}
                                       end={countnum}
                                       delay={0}
                                       duration={COUNT_UP_DURATION}
                                       prefix={prefix ? "£" : ''}
                                       suffix={suffix ? "m" : ''}
                                       separator=","
                                     />
                                   </strong>
                                   {stats.Details && <span>{stats.Details}</span>}
                                 </motion.div>
                               )
                             })}  */}

                        </div>
                      }
                      {props.About_Statistics ?
                        <p><b>Source:</b> Wards, part of Arun Estates Ltd, correct as of {datetime}</p> : ''}
                    </Col>
                    :
                    <Col lg={12} className="ms-auto">
                      <div className="count-row count-row-first">
                        {props.Add_Stats && props.Add_Stats.slice(0, 2).map((stats, i) => {
                          var countnum = stats.Count.replace(/[^\w\s]/gi, '')
                          var prefix = ''
                          if (stats.Count.indexOf('£') !== -1) {
                            prefix = '£'
                          }
                          return (
                            <motion.div variants={contentItem} className="count-block one">
                              <strong><CountUp
                                start={inView ? COUNT_UP_START : countnum}
                                end={countnum}
                                delay={0}
                                duration={COUNT_UP_DURATION}
                                prefix={prefix ? "£" : ''}
                                separator=","
                              /></strong>
                              {stats.Details && <span>{stats.Details}</span>}
                            </motion.div>
                          )
                        })}
                      </div>
                      {props.Add_Stats?.length > 3 &&
                        <div className="count-row count-row-last">
                          {props.Add_Stats && props.Add_Stats.slice(2, 10).map((stats, i) => {
                            var countnum = stats.Count.replace(/[^\w\s]/gi, '').replace(/\D/g, '');
                            var prefix = ''
                            var suffix = ''
                            if (stats.Count.indexOf('£') !== -1) {
                              prefix = '£'
                            }
                            if (stats.Count.charAt(stats.Count.length - 1) == "m") {
                              suffix = 'm'
                            }
                            return (
                              <motion.div key={stats + 2} custom={stats + 3} variants={contentItem} className="count-block one">
                                <strong>
                                  <CountUp
                                    start={inView ? COUNT_UP_START : countnum}
                                    end={countnum}
                                    delay={0}
                                    duration={COUNT_UP_DURATION}
                                    prefix={prefix ? "£" : ''}
                                    suffix={suffix ? "m" : ''}
                                    separator=","
                                  />
                                </strong>
                                {stats.Details && <span>{stats.Details}</span>}
                              </motion.div>
                            )
                          })}
                        </div>
                      }
                      {props.About_Statistics ?
                        <p><b>Source:</b> Wards, part of Arun Estates Ltd, correct as of {datetime}</p> : ''}
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  );
};

export default CountBlock;
