import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import axios from "axios"
import Select, { components } from 'react-select';
import Slider from "react-slick";
import GooglePost from "../../images/google_post.png"
import GoogleReviewCount from "../GoogleReviewCount/GoogleReviewCount";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import star from '../../images/star.svg';

import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import "./GoogleReviews.scss"
let arrayForHoldingPosts = [];
const GoogleReviews = (props) => {
  const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
  const [reviewLocation, setReviewLocation] = useState(null)
  const [branchName, setBranchName] = useState(props.branch_name)
  const [reviews, setReviews] = useState([])
  const [show, setShow] = useState(-1);
  const [next, setNext] = useState(props.isBranchDetailPage ? 3 : 15);
  const [postsToShow, setPostsToShow] = useState([]);
  const postsPerPage = props.isBranchDetailPage ? 3 : 15;
  console.log("Ucant see",props)
  const showComment = (k) => {
    if (show == k) {
      setShow(-1);
    } else {
      setShow(k);
    }

  };
  function getHighestReviewLocationIndex(data) {
    const wardsLocations = data.filter(location => location.locationName === "Wards of Canterbury Estate Agents");

    // If we have any "Wards of Canterbury Estate Agents" locations
    if (wardsLocations.length > 0) {
        // Find the index of the location with the highest totalReviewCount
        const highestReviewIndex = wardsLocations
            .map((location, index) => ({ index: data.indexOf(location), totalReviewCount: location.totalReviewCount }))
            .reduce((max, location) => location.totalReviewCount > max.totalReviewCount ? location : max).index;

        return highestReviewIndex;
    }

    return 0; // Return 0, if no matching locations found show the first location as fallback .
}


  const getReviewLocation = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FEED_TOKEN}`,
        },
      })
      if (data.status) {
        if (data.data && data.data.length > 0) {
          if(branchName=="Canterbury"){ 
          const indexOfReviewLocation=getHighestReviewLocationIndex(data.data);
          setReviewLocation(data.data[indexOfReviewLocation])
          }else{
          setReviewLocation(data.data[0])
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const getReviews = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FEED_TOKEN}`,
        },
      })
      if (data.status) {
        let revData = data.data?.filter((rev) => (rev.comment));
        setReviews(revData)
        loopWithSlice(0, postsPerPage, revData);
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {

    if (!reviewLocation) {
      let branch = branchName
      if(branchName.includes("Folkestone")){
        branch = "Folkstone"
      }
      let url = process.env.GATSBY_STRAPI_FEED_SRC + `/rest-api/google-review-location?_fields=name,locationName,averageRating,totalReviewCount,updatedAt&locationName_contains=${encodeURIComponent(branch=="Fine & Country Canterbury"? "Fine & Country Estate Agents Canterbury": branch)}`;
      getReviewLocation(url)
    }
    if (reviewLocation) {
      if (props.isBranchDetailPage) {
        props.setTotalReviewCount(reviewLocation?.totalReviewCount)
        props.setAverageRating(reviewLocation?.averageRating)
      }
      let reviewUrl = process.env.GATSBY_STRAPI_FEED_SRC + `/rest-api/google-review-review?_fields=starRating,createTime,reviewer,comment&name_contains=${reviewLocation.name}&_sort=-createTime&_limit=100&starRating_contains=FIVE|FOUR`;
      getReviews(reviewUrl)
    }

  }, [reviewLocation])


  const reviewAgo = (createTime) => {
    const date1 = new Date()
    const date2 = new Date(createTime)

    var diff = Math.floor(date1.getTime() - date2.getTime())
    var day = 1000 * 60 * 60 * 24

    var days = Math.floor(diff / day)
    var months = Math.floor(days / 31)
    var years = Math.floor(months / 12)

    const reviewWritten =
      years !== 0
        ? years + " years ago"
        : months !== 0
          ? months + " months ago"
          : days + " days ago";
    return reviewWritten;
  }

  const loopWithSlice = (start, end, reviews) => {
    const slicedPosts = reviews.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const loopWithSliceMore = (start, end) => {
    const slicedPosts = reviews.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSliceMore(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  const customStyles = {
    control: styles => ({
      ...styles, outline: 0, border: 'none', boxShadow: 'none', borderRadius: '8px', backgroundColor: '#CCE7EE',
      color: 'red', fontWeight: 'bold', height: "48px", width: "100%", paddingLeft: "10px", paddingRight: "10px"
    }),
    singleValue: base => ({
      ...base,
      color: '#005670'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#005670", // Custom colour
      fontSize: "24px"
    })

  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? '-' : '+'}
      </components.DropdownIndicator>
    );
  };

  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="insights-row google-reviews property pt-5 pb-0"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}

        >
          <Container>
            <Row>
              {props.isBranchDetailPage &&
                <Col md={12}>
                  <motion.div variants={fadeInFromTop} className="title">
                    <span className="sm-heading">What Our Customers Are Saying</span>
                    <h2>Real feedback from trusted Google reviews</h2>
                  </motion.div>
                </Col>
              }
              <Col className="d-flex align-item-baseline justify-content-between flex-wrap mb-5">

                <div className="google-review-badge">
                  {reviewLocation &&
                    <motion.div variants={fadeInFromTop} className="google-review">
                      <GoogleReviewCount
                        dark={true}
                        averageRating={reviewLocation?.averageRating}
                        totalReviewCount={reviewLocation?.totalReviewCount} />
                    </motion.div>
                  }
                </div>
                <div className="btns-list">
                  {!props.isBranchDetailPage &&
                    <Select
                      name={`area`}
                      value={props.allbranches.find((b) => b.value === branchName)}
                      options={props.allbranches}
                      className={``}
                      onChange={(selected) => {
                        setBranchName(selected.value)
                        setReviewLocation(null)
                      }}
                      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                      classNamePrefix="select branch-select-mobile-dropdown"
                      styles={customStyles}
                    />
                  }
                  <a class="btn more-review" href={`https://www.google.com/search?q=Wards of ${encodeURIComponent(branchName)}`} target="_blank"><span>Leave a Review</span><i class="icon-arrow"></i></a>
                </div>


              </Col>
            </Row>
            <div className="review-slider custom-slider">
              <div className="banner-imgslider row">

                {postsToShow.map((item, index) => {
                  const profileUrl = item.reviewer?.profilePhotoUrl;
                  const arrayOfZeros = new Array(wordToNumber[item.starRating]).fill(0);
                  let comment = item.comment
                  if (show != index && comment && comment?.length > 100) {
                    comment = comment.slice(0, 100) + "...";
                  }
                  return (
                    <motion.div key={index} custom={index + 1} variants={fadeInFromTop} className="review-item col-md-4 mb-4">

                      <div className="insights-block">
                        <div className="profile">
                          {profileUrl &&
                            <LazyLoadImage
                              wrapperProps={{ style: { display: "block", width: "50px" } }}
                              alt={'property Image'}
                              effect="blur"
                              src={profileUrl} />
                          }
                          <div className="name">
                            <h6> {item.reviewer?.displayName} </h6>

                            <p>{reviewAgo(item.createTime)}</p>
                          </div>

                        </div>
                        <div className="stars">
                          {arrayOfZeros.map((_, index) => (
                            <img src={star} alt="star--v1" key={index} />
                          ))}
                        </div>

                        <p>
                          {comment} {comment && comment?.length > 100 && <a className="readmore" href="javascript:void(0)" onClick={() => { showComment(index) }}>{show == index ? "Read Less" : "Read More"}</a>}

                        </p>


                        <div className="posted">
                          <img src={GooglePost} alt="star--v1" width="100px" />
                        </div>
                      </div>

                    </motion.div>
                  ) 
                })}

              </div>
              {arrayForHoldingPosts.length !== reviews.length ?
                <div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
                : ''
              }
            </div>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}
export default GoogleReviews
